import { Fragment } from "react";

import HeaderCartButton from "./HeaderCartButton";
import mealsMainImg from "../../assets/meals.jpg";
import classes from "./Header.module.css";

const Header = (props) => {

    return (
        <Fragment>
            <header className={classes.header}>
                <h1> React Meals </h1>
                <HeaderCartButton onClick={props.onShowCart}></HeaderCartButton>
            </header>

            <div className={classes['main-image']}>
                <img src={mealsMainImg} alt='A table full of delicios food ! '></img>
            </div>
        </Fragment>
    );

};


export default Header;