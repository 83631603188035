import { useReducer } from "react";
import CartContext from "./cart-context";



const defaultCartState = {
    items: [],
    totalAmount: 0
};


const cartReducer = (state, action) => {

    if( action.type === 'ADD' ){

        const updatedTotalAmount = state.totalAmount + (action.item.price * action.item.amount);
        
        const existingCartItemIndex = state.items.findIndex(item => item.id === action.item.id);

        const existingCartItem = state.items[existingCartItemIndex];
        let updatedItems;

        if( existingCartItem ){ // if we already have ordered meal
            const updatedItem = {
                ...existingCartItem,
                amount: existingCartItem.amount + action.item.amount
            };
            updatedItems = [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        }else{  // if we adding a meal for the first time
            updatedItems = state.items.concat(action.item);
        }

        // const updatedItems = state.items.concat(action.item);      // adding new element in NEW array
        
        return{
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };
    }else if( action.type === 'REMOVE' ){
        const  existingCartItemIndex = state.items.findIndex(item => item.id === action.id);
        const existingCartItem = state.items[existingCartItemIndex];

        const updatedTotalAmount = state.totalAmount-existingCartItem.price;

        let updatedItems;
        if( existingCartItem.amount === 1){
            updatedItems = state.items.filter(item => item.id !== action.id);
        }else{
            const updatedItem = {...existingCartItem, amount: existingCartItem.amount - 1 };
            updatedItems =  [...state.items];
            updatedItems[existingCartItemIndex] = updatedItem;
        }

        return{
            items: updatedItems,
            totalAmount: updatedTotalAmount
        };

    }

    return defaultCartState;

};


const CartProvider = (props) => {

    const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);

    const addItemToCartHandler = (item) => {
        dispatchCartAction({type: 'ADD', item: item});
    };

    const removeItemFromCartHandler = (id) => {
        dispatchCartAction({type: 'REMOVE', id: id});
    };

    const cartContext = {
        items: cartState.items,
        totalAmount: cartState.totalAmount,
        addItem: addItemToCartHandler,
        removeItem: removeItemFromCartHandler
    };

    return(
        <CartContext.Provider   value={cartContext}>
            {props.children}
        </CartContext.Provider>
    );
};


export default CartProvider;