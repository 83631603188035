import { useRef, useState } from "react";

import classes from "./MealItemForm.module.css";
import Input from "../../UI/Input";


const MealItemForm = (props) => {

    const [amountIsValid, setAmountIsValid] = useState(true);

    const amountInputRef = useRef();

    const submitHandler = (event) => {
        
        event.preventDefault(); 
        
        const enteredAmount = amountInputRef.current.value;
        const enteredAmountInt = +enteredAmount;

        if( enteredAmount.trim().length === 0 || enteredAmountInt<1 || enteredAmountInt>5 ){
            
            setAmountIsValid(false);
            return;
        }

        props.onAddToCart(enteredAmountInt);

    };

    return(

        <form className={classes.form} onSubmit={submitHandler}>
            <Input 
                ref={amountInputRef}
                label="Amount"
                input={{
                    id: 'amount_' + props.id,
                    type: 'number',
                    min: '1',
                    max: '5',
                    step: '1',
                    defaultValue: '1',
                }}
            ></Input>
            {!amountIsValid && <p style={{color: "red"}}>Please enter a valid amount [ 1 - 5 ] </p>}
            <button> + Add Meal </button>
            
        </form>
    );

};

export default MealItemForm;