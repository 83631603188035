import ReactDom from "react-dom";           // for PORTALS
import { Fragment } from "react";
import classes from "./Modal.module.css";


const Backdrop = (props) => {

    return(
        <div className={classes.backdrop} onClick={props.onClose}></div>
    );

};

const ModalOverlay = (props) => {

    return(
        <div className={classes.modal}>
            <div className={classes.content}>{props.children}</div>
        </div>
    );

};

const portalElement = document.getElementById('overlays');

const Modal = (props) => {

    return(
        <Fragment>
            { ReactDom.createPortal(<Backdrop onClose={props.onClose}></Backdrop>, portalElement) }
            { ReactDom.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement) }
        </Fragment>
    );

};

export default Modal;